.login-section {
	display: block;
	padding: 40px 0;

	.container {
		padding: 0 15px;
		width: 100%;
		display: flex;
		align-items: stretch;
		gap: 15px;

		@media only screen and (min-width: 1024px) {
			padding: 0 40px;
			gap: 40px;
		}
	}
}
