.input {
	margin-bottom: 16px;

	&__notification {
		margin-top: 5px;
	}

	&__name {
		color: var(--gray600);
		//font-family: Euclid Circular A;
		font-size: 14px;
		font-weight: 400;
		margin-bottom: 8px;
	}

	&__notification--type2 {
		a {
			font-size: 12px;
			font-weight: 400;
			color: var(--blue400);
		}
	}
}

.totp .input__notification--type2 a {
	font-size: 12px;
	font-weight: 400;
	color: var(--blue400);
}
