:root {
	--transition-base: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
	--font-sans: 'Euclid Circular A', sans-serif;
	--white500: #ffffff;
	--gray100: #e4e4e4;
	--gray300: #fafafa;
	--gray400: #f2f2f2;
	--gray600: #636363;
	--gray800: #141414;
	--blue400: #0071ab;
	--blue600: #212c63;
	--additional-error: #fe0000;
	--primary-blue-500: #4A4E82;
	--animate-duration: 300ms;
}

@import 'global/global-fonts';
@import 'global/global-scaffolding';

@import 'react-phone-input-2/lib/material.css';
@import 'custom/index';

@import 'blocks/login-section/login-section';
@import 'blocks/login/login';
@import 'blocks/login-banner/login-banner';
@import 'blocks/login-form/login-form';
@import 'blocks/input/input';
@import 'blocks/input-item/input-item';
@import 'blocks/footer/footer';
@import 'blocks/footer-nav/footer-nav';
@import 'blocks/popup/popup';
@import 'blocks/toastify/toastify';
@import 'blocks/sidebar-nav/sidebar-nav';
@import 'blocks/page-wrap/page-wrap';
