.setting-content-body-item {
	border: none !important;
}

.setting-content-title-twoFa {
	justify-content: center;
}

.setting-section {
	padding: 40px 0 154px;
}

