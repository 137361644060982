.input-item {
	height: 44px;
	border: 1px solid var(--gray100);
	border-radius: 10px;
	padding: 0 16px;
	font-size: 14px;
	font-weight: 500;
	color: var(--gray800);
	font-family: var(--font-sans);

	&::placeholder {
		font-size: 14px;
		font-weight: 500;
		font-family: var(--font-sans);
	}
}
