.login-recaptcha {
	margin: 14px 0 24px;
}

.user-header--centred {
	display: flex;
	align-items: center;
	justify-content: center;
}

.input__notification {
	&--right {
		display: flex !important;
		justify-content: flex-end !important;
	}
}

