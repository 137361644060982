.footer {
	border-top: 1px solid var(--gray100);
	background: var(--gray300);

	.footer-container {
		padding: 30px 15px;
		display: flex;

		@media only screen and (min-width: 768px) {
			align-items: center;
			justify-content: space-between;
		}

		@media only screen and (min-width: 1024px) {
			padding: 30px 40px;
		}

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			align-items: center;
		}
	}

	.logo {
		width: 158px;

		@media only screen and (max-width: 767.98px) {
			margin-bottom: 30px;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}
}
