.sidebar-nav {
	background: var(--blue600);
	padding: 40px 26px;

	&__logo {
		padding: 0 0 40px;
	}

	.sidebar-nav-item__list-item {

		&--has-children {

			> .sidebar-nav-item__link {
				position: relative;

				&:after {
					content: '';
					background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.75 7.5L9.35355 10.8964C9.15829 11.0917 8.84171 11.0917 8.64645 10.8964L5.25 7.5' stroke='%23E4E4E4' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
					width: 18px;
					min-width: 18px;
					height: 18px;
					min-height: 18px;
					position: absolute;
					top: calc(50% - 9px);
					right: 20px;
					transition: var(--transition-base);
				}

				&.active {

					&:after {
						transform: rotate(180deg);
					}
				}

				&.active + .sidebar-nav-item__submenu {
					display: block;
				}
			}
		}
	}

	.sidebar-nav-item__list {

		ul {
			display: flex;
			flex-direction: column;
			gap: 6px;

			li {

				a, .sidebar-nav-item__link, button {
					padding: 15px 20px;
					display: flex;
					align-items: center;
					gap: 10px;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					color: var(--gray100);
					border-radius: 8px;
					background: transparent;
					cursor: pointer;
					width: 100%;
					font-family: var(--font-sans);
					transition: var(--transition-base);

					&:hover,
					&.active {
						background: var(--primary-blue-500);
						color: #FFF;
						font-weight: 600;
						font-family: var(--font-sans);

						/*svg path {
							stroke: none;
						}*/
					}
				}
			}
		}
	}

	.sidebar-nav-item__icon {
		width: 20px;
		min-width: 20px;
		height: 20px;
	}

	.sidebar-nav-item__submenu {
		display: none;

		ul {
			gap: 0;

			li {

				a, .sidebar-nav-item__link {

					&:hover,
					&.active {
						background: transparent;
					}
				}
			}
		}
	}
}
