.login {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 14px;
	border: 1px solid var(--gray400);
	background: var(--white500);
	padding: 24px;

	@media only screen and (min-width: 768px) {
		width: 50%;
	}

	@media only screen and (min-width: 1024px) {
		width: 100%;
		padding: 40px;
	}
}

.login-recaptcha {
	margin: 16px 0 30px 0;
}
