.login-form {
	width: 100%;
	max-width: 400px;
	gap: 30px;

	&__title {
		font-weight: 600;
		font-size: 38px;
		color: var(--gray800);
		text-align: center;
	}
}
