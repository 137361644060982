.footer-nav {
	ul {
		display: flex;
		align-items: center;
		gap: 40px;

		@media only screen and (max-width: 767.98px) {
			flex-direction: column;
			gap: 16px;
		}

		> li > a,
		> li > button {
			color: var(--blue400);
			font-size: 16px;
			font-weight: 400;
			transition: all var(--transition-base);
		}
	}
}
