.verification {
	.disabled {
		cursor: not-allowed;
	}
	.form-body {
		margin-top: 0;
	}
	.button--type3:hover {
		color: #777e90;
		background: #fff;
	}
	.button--type3:hover {
		color: #fff;
		background: var(--accent-color);
	}
}

.verification-upload {
	&.active {
		border-color: #8f9195;
		background: #f5f5f5;
	}
}
